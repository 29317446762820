import React from "react";
import { Link } from "gatsby";

const PrevNextNavFooter = (props) => {
  return (
    <div style={{ display: "flex", height: "240px" }}>
      <div style={{ width: "50%", paddingLeft: "80px" }}>
        {props.previousText ? (
          <Link to={props.previousUrl}>
            <div
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "80px",
                color: "black",
              }}
            >
              &lt;&lt; {props.previousText}
            </div>
            <div
              className="section-header"
              style={{
                backgroundColor: "white",
                paddingTop: "32px",
                paddingBottom: "24px",
              }}
            >
              <h2 className=" ui header">{props.previousHeader}</h2>
            </div>
          </Link>
        ) : null}
      </div>
      <div style={{ width: "50%", textAlign: "right", paddingRight: "80px" }}>
        {props.nextText ? (
          <Link to={props.nextUrl}>
            <div
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "80px",
                color: "black",
              }}
            >
              {props.nextText} &gt;&gt;
            </div>
            <div
              className="section-header"
              style={{
                backgroundColor: "white",
                paddingTop: "32px",
                paddingBottom: "24px",
              }}
            >
              <h2 className=" ui header">{props.nextHeader}</h2>
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  );
};
export default PrevNextNavFooter;
