import * as React from "react";
import Layout from "../../components/Layout";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import empty_png from "../../images/logo/empty.png";
import Shade_of_Black_Desktop_svg from "../../images/6. Colours/Shade_of_Black_Desktop.svg";
import Colour_Mode_Main_Imagery_jpg from "../../images/6. Colours/Colour_Mode_Main_Imagery.jpg";

const Colors = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(235, 10, 30)", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            <p style={{ marginBottom: "40px" }}>
              Colour is a powerful means of recognition that helps establish a
              clear identity and distinction for Toyota and our products.
            </p>
            <p>
              At the heart of our brand is Toyota Red. Red is the colour of
              energy. It is highly visible and conveys the Toyota spirit of
              excitement, passion and possibility of going places.
            </p>
          </h1>
        </div>
      </div>

      <section className="component Section" id="brand-colours">
        <div className="section-header darker-background" data-tag="absent">
          <div className="container">
            <h2 className="ui header">Brand Colours</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn" id="">
            <div className="copy spaced-p">
              Our colour palette is simple and impactful, comprising Toyota Red,
              white, black and grey. These are the colours of our identity, as
              expressed through our logos and our Visual Identity System. In
              addition to the colours within our photography, these are the only
              colours to be used when creating brand communications that sit
              within Messaging Platform. Brand Campaigns can use these colours
              but also have the option to use secondary colours as specified in
              the following page.
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{
            backgroundColor: "#EB0A1E",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
          data-compid="brand-colors-1"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#fff" }}>
              Toyota Red
            </h3>
            <div className="copy" style={{ color: "#fff" }}>
              PMS 186C
              <br />
              CMYK 00 100 90 00
              <br />
              RGB 235 10 30
              <br />
              HEX EB0A1E
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{
            backgroundColor: "white",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
          data-compid="brand-colors-2"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              White
            </h3>
            <div className="copy">
              CMYK 00 00 00 00
              <br />
              RGB 255 255 255
              <br />
              HEX FFFFFF
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{
            backgroundColor: "black",
            paddingTop: "80px",
            paddingBottom: "80px",
          }}
          data-compid="brand-colors-3"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#fff" }}>
              Black
            </h3>
            <div className="copy" style={{ color: "#fff" }}>
              PMS Black C<br />
              CMYK 00 00 00 100
              <br />
              RGB 00 00 00
              <br />
              HEX 000000
            </div>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ padding: 0 }}
          data-compid="brand-colors-4"
        >
          <div className="image">
            <div className="full-width">
              <img alt="" src={Shade_of_Black_Desktop_svg} />
            </div>

            {/* <h3 className="title" style={{color:'#fff'}}>Grey</h3>
                    <div className="copy" style={{color:'#fff'}}>PMS Black C - 80% TINT<br/>CMYK  00 00 00 80<br/>RGB 88 89 91<br/>HEX 58595B</div> */}
          </div>
        </div>
      </section>

      <section
        className="component Section"
        data-index="1"
        data-navindex="9"
        id="color-mode"
      >
        <div className="section-header darker-background" data-tag="absent">
          <div className="container">
            <h2 className="ui header">Colour Mode</h2>
          </div>
        </div>
        <div
          className="blog-content"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-0"
        >
          <div className="container onecolumn" id="">
            <h3 className="title" style={{ color: "#000" }}>
              Toyota Red
            </h3>
            <div className="copy spaced-p">
              Red is considered the primary brand colour and is used sparingly
              to avoid competing with the visual impact of our logo.
            </div>
          </div>
        </div>

        <div
          className="image"
          style={{ backgroundColor: "#fafafa", paddingBottom: "80px" }}
        >
          <div className="full-width">
            <div className="image">
              <img
                alt=""
                src={Colour_Mode_Main_Imagery_jpg}
                style={{ height: "650px" }}
              />
            </div>
          </div>
        </div>

        <div
          className="image"
          style={{ background: "#fafafa" }}
          data-compid="color-mode-1"
        >
          <div className="full-width">
            <div className="image">
              {/* <picture>
                            <source media="(max-width:767px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_mobile.jpg"/>
                            <source media="(min-width:768px) and (max-width:1024px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_tablet.jpg"/>
                            <source media="(min-width:1900px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red.jpg"/>
                            <img alt="" src="/brandguidelines/imgix/color/color-use/colors_use_red_desktop.jpg" alt="National Clearance Event billboard"/>
                        </picture> */}
            </div>
          </div>
        </div>

        <div
          className="checkerboard"
          style={{ background: "#fafafa", marginBottom: "80px" }}
        >
          <div className="full-width">
            <ul>
              <li>
                {/* First row */}
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <div>
                        <div
                          className="image"
                          style={{ display: "flex", height: "570px" }}
                        >
                          {/* intetionally double curly braces around empty_png  */}
                          <img
                            alt=""
                            src={empty_png}
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="right"
                    style={{ margin: "0px", background: "white" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">White</h3>
                        <p className="text">
                          White space allows for information and product to
                          breathe and often promotes greater visibility and
                          impact.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end of first row  */}
              </li>
              <li>
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <div>
                        <div
                          className="image"
                          style={{ display: "flex", height: "570px" }}
                        >
                          <img
                            alt=""
                            src={empty_png}
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="right"
                    style={{ margin: "0px", background: "white" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">Black</h3>
                        <p className="text">
                          Black is generally used for typography only, but may
                          be used elsewhere when deemed necessary.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end of second row  */}
              </li>

              <li>
                {/* Third row */}
                <div className="wrap" style={{ background: "transparent" }}>
                  <div
                    className="left"
                    style={{ margin: "0px", background: "transparent" }}
                  >
                    <div className="block">
                      <div>
                        <div
                          className="image"
                          style={{ display: "flex", height: "570px" }}
                        >
                          <img
                            alt=""
                            src={empty_png}
                            style={{
                              height: "400px",
                              width: "400px",
                              margin: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="right"
                    style={{ margin: "0px", background: "white" }}
                  >
                    <div className="block">
                      <span></span>
                      <div className="copyWrap">
                        <h3 className="title">Grey</h3>
                        <p className="text">
                          Grey may be used as a secondary colour for call-outs
                          and to highlight CTAs.'
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Photography"
        previousUrl="/brandguidelines/photography"
        nextText="Next"
        nextHeader="TVC Endframe"
        nextUrl="/brandguidelines/tvc-endframe"
      />
    </Layout>
  );
};

export default Colors;
