import * as React from "react";
import MainNavigation from "../components/v2/MainNavigation";
// import "../styles/global.css";
import "semantic-ui-css/semantic.min.css";
import "./Layout.module.css";
import PageFooter from "./PageFooter";

class Layout extends React.Component {
  syncMenuWithUrl() {
    // debugger;

    // eslint-disable-next-line
    const pageName = location.pathname.replace(/\/|brandguidelines/gi, "");

    // eslint-disable-next-line
    const sectionId = window.location.hash.replace("#", "");

    const topMenuItem = document.getElementById(pageName);
    const subMenuItem = document.getElementById(`${pageName}-${sectionId}`);

    this._resetSubCategory();
    if (topMenuItem) {
      topMenuItem.setAttribute("class", "active");
    }
    if (subMenuItem) {
      subMenuItem.setAttribute("class", "sub-category active");
    } else {
      // select the 'overview' which is the first submenu item:
      // const subMenuItems = document.querySelectorAll(`#${pageName} .sub-category`);
      const subMenuItems = document.querySelectorAll(
        `[data-subsection="${pageName}"]`
      );

      if (subMenuItems && subMenuItems.length) {
        subMenuItems[0].className = "sub-category active";
      }
    }
  }

  componentDidMount() {
    //debugger;
    // 1) when we loaded a direct URL, need to update the menu

    console.log("componentDidMount");
    this.syncMenuWithUrl();

    // // here attach all event handlers
    // debugger;
    // console.log('mounted');
    if (typeof window !== "undefined") {
      window.addEventListener(
        "hashchange",
        () => {
          //debugger;
          // fires when clicking history and typing the url manually
          // we need to do the menu
          this.syncMenuWithUrl();
        },
        false
      );

      window.addEventListener(
        "load",
        () => {
          //debugger;
          console.log("page-load fired");
          this._scrollToActiveSection();
        },
        false
      );

      // purpose of this is to highlight the item in menu as we scroll
      window.addEventListener("scroll", () => {
        //debugger;
        console.log("page-scroll fired");
        this._handleScroll();
      });
    }
  }

  _handleScroll() {
    // debugger;

    const isElementXPercentInViewport = (elem, percentVisible) => {
      const rect = elem.getBoundingClientRect(),
        windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
      var result = !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      );
      return result;
    };
    const overviewElem = document.querySelector(".page-header");
    if (
      overviewElem &&
      isElementXPercentInViewport(overviewElem, 50) &&
      overviewElem.id
    ) {
      this._resetSubCategory();
      this._highlightSubmenu(overviewElem.id);
    } else {
      // debugger;
      // 1) need to determine currently active section
      const sections = document.querySelectorAll("section");
      // var sectionId = "";

      const visibleSections = [...sections].filter((s) =>
        isElementXPercentInViewport(s, 10)
      ); // NodeList is not an arraty
      const sectionHeaders = document.querySelectorAll(
        "section .section-header h2"
      );
      const visibleSectionHeaders = [...sectionHeaders].filter((h) =>
        isElementXPercentInViewport(h, 80)
      );
      if (visibleSectionHeaders.length) {
        // debugger;
        this._resetSubCategory();
        this._highlightSubmenu(
          visibleSectionHeaders[0].parentElement.parentElement.parentElement.id
        ); // hacky, relies on nesting structure
        return;
      }

      // debugger;
      if (visibleSections.length) {
        this._resetSubCategory();
        // const subCategory = document.getElementsByClassName('active sub-category');
        // for (var i = subCategory.length - 1; i>=0; i--){
        //     subCategory[i].className = subCategory[i].className.replace('active', '');
        // }

        // highlight the menu
        this._highlightSubmenu(visibleSections[0].id);
      } else {
        if (overviewElem && isElementXPercentInViewport(overviewElem, 5)) {
          this._resetSubCategory();
          if (overviewElem.id) {
            this._highlightSubmenu(overviewElem.id);
          } else {
            this._highlightSubmenu(sections[0].id);
          }
        }
      }
    }

    return;

    /*
        // if no section header is visible, look at the section: 
        if (visibleSections.length){
            
        }
        else { // if no sections - check if first sub-item can be marked
            
        
        }

        for (var i = 0; i < sections.length; i++){
            let section = sections[i];
            // first check - if a section header (red text) is visible, then that's the section. 
            var sectionHeader = section.querySelector('.section-header h2');
            if (sectionHeader && isElementXPercentInViewport( sectionHeader, 100 )){
                sectionId = section.id;
                break;
            }
            // second check - if header is not visible, then see which section is visible 
            if ( isElementXPercentInViewport( section, 10 ) ) {
                sectionId = section.id; // need to break here 
                break;
            }

            // ideally need a third check - if two sections are visible, then decide which one has bigger visible area.
        }

        const overviewElem1 = document.querySelector('.page-header');
        if (overviewElem &&  isElementXPercentInViewport( overviewElem, 100 ) ) {
            this._resetSubCategory();
            this._highlightSubmenu(overviewElem.id);
        }
        else {

        

            // sections.forEach( ( section ) => {
            //     // debugger;
            //     if ( isElementXPercentInViewport( section, 1 ) ) {
            //         sectionId = section.id; // need to break here 
            //     }
            // } );
            if (sectionId){
                // remove selection from each menu item:
                // document.querySelectorAll('.subcategory').forEach(m=>{
                    
                // })

                debugger;
                this._resetSubCategory();
                // const subCategory = document.getElementsByClassName('active sub-category');
                // for (var i = subCategory.length - 1; i>=0; i--){
                //     subCategory[i].className = subCategory[i].className.replace('active', '');
                // }


                // highlight the menu 
                this._highlightSubmenu(sectionId);
                
                // TODO: if section is found, but it's the top of the page and there is the 'Overview' element, then make Overview highlighted 

                // eslint-disable-next-line
                // const pageName = location.pathname.replace(/\/|brandguidelines/ig, '');
                // const subMenuItem = document.querySelector(`.sub-category#${pageName}-${sectionId}`);
                // //const subMenuItem = document.querySelector(`.sub-category#${sectionId}`);
                // if (subMenuItem){
                //     debugger;
                //     subMenuItem.setAttribute('class', 'active sub-category');
                // }
            }
        }

        return;

        

        // return;
        debugger;
        // scroll happens as part of the standard navigation (anchor link makes browser scroll)
        // do the same thing as in scroll to activeSection:
        const hash = window.location.hash.replace('#', '');

        // this bit happens automatically
        // if (hash){
        //     const elem = document.getElementById(hash);
        //     if (elem){
        //         elem.scrollIntoView();
        //     }
        // }

        // eslint-disable-next-line
        const pageName = location.pathname.replace(/\/|brandguidelines/ig, ''); // this will be "photography"
        this._resetSubCategory(); 
        if (hash && pageName){
            const pageElem = document.getElementById(`${pageName}-${hash}`); // eg photography-people
            if (pageName){
                pageElem.setAttribute('class', 'active');
            }

        }

        const activeSection = this.getActiveSection();

        // eslint-disable-next-line
        // const pageName = location.pathname.replace(/\/|brandguidelines/ig, ''); 
        const activeSectionTitle = activeSection && activeSection.getAttribute('id');
        const subMenu = document.querySelector(`[id*="${pageName}-${activeSectionTitle}"]`);
        const overviewMenu = document.getElementById(`${pageName}-overview`);
    
        if (!!activeSection || window.scrollY < 100){
          //this._resetSubCategory(); TODO later 
        }
    
        if (overviewMenu && subMenu && activeSectionTitle){
          overviewMenu.className = 'sub-category';
          subMenu.className = 'sub-category-active';
        }
    
        if (window.scrollY  < 150 || activeSectionTitle === 'section-0' || !activeSectionTitle){
          if (overviewMenu){
            overviewMenu.className = 'sub-category-active';
          }
          if (activeSectionTitle && subMenu){
            subMenu.className ='sub-category';
          }
        }
        */
  }

  getActiveSection() {
    const classesNodeList = document.querySelectorAll(".active-section");
    const activeSections = Array.prototype.slice
      .call(classesNodeList)
      .map((element) => element);
    const activeSection = activeSections[activeSections.length - 1];
    return activeSection;
  }

  _resetSubCategory() {
    // // debugger;
    // const subCategory = document.getElementsByClassName('active sub-category');
    // for (var i = subCategory.length - 1; i>=0; i--){
    //     subCategory[i].className = subCategory[i].className.replace('active', '');
    // }

    const subCategory = document.getElementsByClassName("active sub-category");
    for (var i = subCategory.length - 1; i >= 0; i--) {
      subCategory[i].className = subCategory[i].className.replace("active", "");
    }
  }

  _highlightSubmenu(sectionId) {
    // eslint-disable-next-line
    const pageName = location.pathname.replace(/\/|brandguidelines/gi, "");
    const subMenuItem = document.querySelector(
      `.sub-category#${pageName}-${sectionId}`
    );
    //const subMenuItem = document.querySelector(`.sub-category#${sectionId}`);
    if (subMenuItem) {
      subMenuItem.setAttribute("class", "active sub-category");
    }
    const topMenuItem = document.querySelector(`a#${pageName}`);
    if (topMenuItem) {
      topMenuItem.scrollIntoView();
    }
  }

  _scrollToActiveSection() {
    // the purpose of this is to scroll to active section when the page is loaded with a hash part in the url
    debugger;
    console.log("scroll to active section");

    // eslint-disable-next-line
    const pageName = location.pathname.replace(/\/|brandguidelines/gi, "");

    // eslint-disable-next-line
    const hash = window.location.hash.replace("#", "");

    if (!hash) {
      return;
    }

    this._resetSubCategory();

    if (pageName) {
      const topMenuItem = document.getElementById(pageName);
      if (topMenuItem) {
        topMenuItem.setAttribute("class", "active");
      }
    }

    if (hash && pageName) {
      const subMenuItem = document.getElementById(`${pageName}-${hash}`);
      if (subMenuItem) {
        subMenuItem.setAttribute("class", "sub-category active");
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  subMenuItemClick(e) {
    // 1) we call preventDefault which means we need to display the section manually:
    e.preventDefault();
    // eslint-disable-next-line
    history.pushState({}, "", e.target.href);
    const clickedMenuItemId = e.target.id; // each submenu must be marked with id attribute
    // eslint-disable-next-line
    const pageName = location.pathname.replace(/\/|brandguidelines/gi, "");
    if (pageName && clickedMenuItemId) {
      // TODO: clear all active classes

      this._resetSubCategory();

      // expand the top menu item
      //const parentMenuItem = document.getElementById(pageName);
      // if (parentMenuItem){
      //     parentMenuItem.setAttribute('class', 'active');
      // }

      // const clickedMenuItem = document.getElementById(sectionId);
      // if (clickedMenuItem){
      //     clickedMenuItem.setAttribute('class', 'sub-category active');
      // }

      e.target.setAttribute("class", "sub-category active");

      const sectionId = clickedMenuItemId.replace(`${pageName}-`, ""); // clickedMenuItemId is id of the menu item clicked, eg photography-product. sectionID is 'product'
      const sectionElem = document.getElementById(sectionId);
      if (sectionElem) {
        sectionElem.scrollIntoView();
      }
    }
  }

  render() {
    console.log("old Layout is being loaded.");
    return (
      <div className="ui one column grid main-grid">
        {/* <MobileNav/> */}

        <main className="content">
          {this.props.children}
          <PageFooter />
        </main>

        <div className="sidebar">
          <MainNavigation
            subMenuItemClick={(e) => this.subMenuItemClick(e)}
          ></MainNavigation>
        </div>
      </div>
    );
  }
}

export default Layout;
