import React from "react";
const PageFooter = () => {
  return (
    <div className="page-footer">
      <div className="container">
        <div className="copyright">© 2021 Toyota New Zealand</div>
        <div className="footer-links">
          <a
            className="links"
            href="https://www.toyota.com/support/privacy-rights"
          >
            Privacy Policy
          </a>
          |
          <a
            className="links"
            href="https://www.toyota.com/support/legal-terms"
          >
            Legal Terms
          </a>
        </div>
        <div
          style={{
            clear: "both",
            textAlign: "center",
            lineHeight: "1.75",
            paddingTop: "10px",
          }}
        >
          Vehicles represented on this site are for composition purposes only.
          The vehicles do not necessarily represent current model-year options
          or specifications. Please see your local dealer or toyota.com for
          product offerings.
        </div>
      </div>
    </div>
  );
};
export default PageFooter;
